const preparePathParams = (params: { project_id?: string; vendor_id?: string; status?: string }) => {
  if (Object.keys(params).length === 0) {
    return '';
  }
  return Object.keys(params)
    .reduce((acc, key) => {
      if (params[key as keyof typeof params]) {
        acc.push(`${key}=${params[key]}`);
      }
      return acc;
    }, [])
    .join('&');
};

export default preparePathParams;
