import { useMemo, useRef, useState, useEffect } from 'react';
import { Grid, CircularProgress, Link as LinkMui, Stack } from '@mui/material';
import { Receipt as ReceiptIcon, Search as SearchIcon } from '@mui/icons-material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';

import invoiceAPI from '@global-apis/invoice';
import accountingAPI from '@global-apis/accounting';

import './styles.scss';

const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => {
      setDebouncedValue(value);
    }, [delay]);

    return () => {
      clearTimeout(id);
    };
  }, [value, delay]);

  return debouncedValue;
};

const getNumber = (str) => {
  return str.replace('$', '').replaceAll(',', '');
};

const TopbarSearch = () => {
  const navigate = useNavigate();

  const [isShowing, setIsShowing] = useState(false);

  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [isLoadingContract, setIsLoadingContract] = useState(false);

  const [isFetchedInvoice, setIsFetchedInvoice] = useState(false);
  const [isFetchedContract, setIsFetchedContract] = useState(false);

  const [invoiceResult, setInvoiceResult] = useState([]);
  const [contractResult, setContractResult] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsShowing(true);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const noInvoiceInteraction = useMemo(
    () => !isFetchedInvoice && !isLoadingInvoice,
    [isFetchedInvoice, isLoadingInvoice]
  );

  const noContractInteraction = useMemo(
    () => !isFetchedContract && !isLoadingContract,
    [isFetchedContract, isLoadingContract]
  );

  const noResultInvoice = useMemo(
    () => isFetchedInvoice && invoiceResult.length === 0,
    [isFetchedInvoice, invoiceResult]
  );

  const noResultContract = useMemo(
    () => isFetchedContract && contractResult.length === 0,
    [isFetchedContract, contractResult]
  );

  const reset = () => {
    setInvoiceResult([]);
    setContractResult([]);
    setSearchTerm('');

    setIsLoadingContract(false);
    setIsLoadingInvoice(false);

    setIsFetchedContract(false);
    setIsFetchedInvoice(false);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleLinkClick = (item) => {
    setIsShowing(false);
    reset();
    navigate(item.redirectTo);
  };

  const onSearch = (q) => {
    const promises = [];
    const newResult = [];

    setInvoiceResult([]);
    setContractResult([]);

    setIsLoadingContract(true);
    setIsLoadingInvoice(true);

    setIsFetchedContract(false);
    setIsFetchedInvoice(false);

    //to search by amount the user will have to type $, otherwise, we'll search by invoice id
    const isSearchingAmount = q.charAt(0) === '$';
    const params = isSearchingAmount ? { amount_total: getNumber(q) } : { q };

    promises.push(
      accountingAPI.purchaseOrder.search(1, { q, page_size: 15 }).then((res) => {
        if (res.data) {
          const newData = [
            ...newResult,
            ...res.data.results.map((item, index) => {
              return {
                id: index,
                title: `${item.order_number} from project ${item.project.name}`,
                icon: <ReceiptIcon sx={{ fontSize: '20px' }} />,
                redirectTo: `/purchase-order/${item.id}`
              };
            })
          ];

          setContractResult(newData);
          setIsLoadingContract(false);
          setIsFetchedContract(true);
        }
      })
    );

    promises.push(
      invoiceAPI.search(1, { ...params, page_size: 15 }).then((res) => {
        if (res.data) {
          const newData = [
            ...newResult,
            ...res.data.results.map((item, index) => {
              const project = item.project ? item.project.name : 'UNASSIGNED PROJECT';
              const vendor = item.vendor ? item.vendor.name : 'UNASSIGNED VENDOR';
              return {
                id: index,
                title: `#${item.invoice_id} from ${vendor} to ${project} Project (${item.flow_status})`,
                icon: <ReceiptIcon sx={{ fontSize: '20px' }} />,
                redirectTo: `/invoice/${item.id}`
              };
            })
          ];

          setInvoiceResult(newData);
          setIsLoadingInvoice(false);
          setIsFetchedInvoice(true);
        }
      })
    );

    Promise.allSettled(promises).then(() => {
      if (!isShowing) {
        setIsShowing(true);
      }
    });
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      onSearch(debouncedSearchTerm);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const searchInputRef = useRef();
  useHotkeys(
    'alt+s',
    (keyboardEvent) => {
      if (keyboardEvent.code === 'KeyS') {
        searchInputRef.current.focus();
      }
    },
    // by default useHotKeys and input are triggered by pressing the keyboard key (keyDown), the “D key” is printed on the input right after it is focused. To solve this, the useHotKey will be triggered when unpressing the keyboard key and the “D key” will not be printed.
    {
      scopes: 'topbar-search',
      keydown: false,
      keyup: true
    }
  );

  return (
    <Grid
      display={{ mobile: 'none', tablet: 'flex' }}
      flexDirection="row"
      alignItems="center"
      data-testid="topbar-search">
      <Dropdown isOpen={isShowing} toggle={() => setIsShowing(!isShowing)} className="app-search">
        <DropdownToggle tag="a" style={{ display: 'none' }}></DropdownToggle>
        <form>
          <fieldset>
            <Grid display="flex" flexDirection="row" justifyContent="center" position="relative">
              <div className="input-group" id="top_bar_search_invoicesearch">
                <input
                  id="topbar-search-input"
                  className="form-control dropdown-toggle"
                  data-testid="topbar-search-input"
                  placeholder="Search..."
                  value={searchTerm}
                  ref={searchInputRef}
                  onChange={handleChange}
                  onClick={handleButtonClick}
                />
                <SearchIcon sx={{ fontSize: '20px' }} className="search-icon" />

                <div className="input-group-append">
                  <button
                    className="btn btn-primary app-search__button"
                    style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}
                    onClick={handleButtonClick}>
                    Search
                  </button>
                </div>
              </div>

              <DropdownMenu className="dropdown-menu-animated topbar-dropdown-menu" data-testid="topbar-search-results">
                <div className="topbar-search__list">
                  <div className="topbar-search__list__group" data-testid="topbar-search-results-invoices">
                    <div
                      className="topbar-search__list__group__title"
                      data-testid="topbar-search-results-invoices-title">
                      Invoices
                    </div>

                    {isLoadingInvoice && (
                      <div className="topbar-search__list__group__loading">
                        <CircularProgress color="inherit" size={14} />
                      </div>
                    )}

                    {noInvoiceInteraction && (
                      <div
                        className="topbar-search__list__group__no-result"
                        data-testid="topbar-search-results-invoices-no-interaction">
                        Please enter a search query or amount value starting in $ to see results
                      </div>
                    )}

                    {noResultInvoice && (
                      <div
                        className="topbar-search__list__group__no-result"
                        data-testid="topbar-search-results-invoices-no-match">
                        There are no results that match your search
                      </div>
                    )}

                    {invoiceResult.map((item, i) => {
                      return (
                        <LinkMui
                          key={i}
                          color="#98a6ad"
                          underline="none"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleLinkClick(item)}
                          className="dropdown-item notify-item">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            {item.icon}
                            <span>{item.title}</span>
                          </Stack>
                        </LinkMui>
                      );
                    })}
                  </div>

                  <div className="topbar-search__list__group" data-testid="topbar-search-results-contracts">
                    <div
                      className="topbar-search__list__group__title"
                      data-testid="topbar-search-results-contracts-title">
                      Contracts
                    </div>

                    {isLoadingContract && (
                      <div className="topbar-search__list__group__loading">
                        <CircularProgress color="inherit" size={14} />
                      </div>
                    )}

                    {noContractInteraction && (
                      <div
                        className="topbar-search__list__group__no-result"
                        data-testid="topbar-search-results-contracts-no-interaction">
                        Please enter a search query or amount value starting in $ to see results
                      </div>
                    )}

                    {noResultContract && (
                      <div
                        className="topbar-search__list__group__no-result"
                        data-testid="topbar-search-results-contracts-no-match">
                        There are no results that match your search
                      </div>
                    )}

                    {contractResult.map((item, i) => {
                      return (
                        <LinkMui
                          key={i}
                          color="#98a6ad"
                          underline="none"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleLinkClick(item)}
                          className="dropdown-item notify-item">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            {item.icon}
                            <span>{item.title}</span>
                          </Stack>
                        </LinkMui>
                      );
                    })}
                  </div>
                </div>
              </DropdownMenu>
            </Grid>
          </fieldset>
        </form>
      </Dropdown>
    </Grid>
  );
};

export default TopbarSearch;
