import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';

import packageInfo from '../../package.json';

const APP_VERSION = packageInfo.version;
const APP_NAME = packageInfo.name;

// Your existing configuration
const sentryConfiguration = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENV,
  release: `${APP_NAME}@${APP_VERSION}`,
  ignoreErrors: [
    /^Request failed with status code 404$/,
    /Loading chunk/,
    /Loading CSS chunk/,
    /Unable to preload CSS for/,
    /JavaScript MIME type/,
    /getBoundingClientRect/,
    /ChunkLoadError/,
    /^.*Failed to fetch dynamically imported module.*$/,
    /^.*error loading dynamically imported module*$/,
    /^.*Request failed with status code 404.*$/
  ],

  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.3, // Sentry.BrowserTracing
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [/https?:\/\/(.*)(backend\.)?inbuild\.ai(.*)/],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false
    }),
    new posthog.SentryIntegration(
      posthog,
      import.meta.env.VITE_SENTRY_ORGANIZATION_NAME,
      import.meta.env.VITE_SENTRY_PROJECT_ID
    )
  ],

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeSend(event: any, hint: any) {
    const regex = /https?:\/\/(.*)(backend\.)?inbuild\.ai(.*)sync-documents/;

    const statusCode = hint?.originalException?.request?.status;

    // ignore 422 and 500 error from sync-documents
    if (regex.test(hint?.originalException?.request?.responseURL) && (statusCode === 422 || statusCode === 500)) {
      return null;
    }

    return event;
  }
};

export const initializeSentry = (userId?: number) => {
  if (userId && [24].includes(userId)) {
    Sentry.init({
      ...sentryConfiguration,
      replaysSessionSampleRate: 1
    });
    // ... other logic specific to user
  } else {
    Sentry.init(sentryConfiguration);
  }

  // ... any other Sentry-related initialization code
};

export default sentryConfiguration;
