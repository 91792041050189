import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import posthog from 'posthog-js';

import App from './App';

import * as serviceWorker from './serviceWorker';
import { initializeSentry } from '@global-utils/sentryConfig';

// prettier-ignore
import { ALLOWED_THIRD_APP } from '@root/constants';

if (ALLOWED_THIRD_APP) {
  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GTM_KEY,
    auth: import.meta.env.VITE_GTM_AUTH,
    preview: import.meta.env.VITE_GTM_PREVIEW
  };

  TagManager.initialize(tagManagerArgs);

  initializeSentry();
}

const container = document.getElementById('root');

if (!container) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(container);
root.render(<App />);

serviceWorker.register();

document.body.addEventListener('plugin_web_update_notice', (e) => {
  const { version, options } = e.detail;

  // eslint-disable-next-line no-console
  console.log('System update!', version, options);
  posthog.capture(`Console: System update!: ${version}`);
});

if (window.pluginWebUpdateNotice_) {
  window.pluginWebUpdateNotice_.onClickRefresh = (version) => {
    posthog.capture(`Clicked: System update refresh: ${version}`);
    window.location.reload();
  };
}
