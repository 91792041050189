import { Fragment, useMemo, useEffect, useState, useRef } from 'react';
import { Stack, Box, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ADMIN, MANAGER } from '@global-utils/defaultValues';
import preparePathParams from '@global-utils/preparePathParams';

import { useMenuProjectList } from '@global-hooks/project';

import { useAuth } from '@global-contexts/auth';
import useRoute from '@global-helpers/route';

import dotsLoading from '@global-assets/images/dots-loading.svg';

import {
  MenuItemProps,
  MenuItemWithChildrenProps,
  MenuItemWithoutChildrenProps,
  AppMenuProps
} from '@global-components/AppMenu/types';
import MenuProject from '@global-components/AppMenu/components/MenuProject';

import './styles.scss';

const MenuItemWithChildren = ({
  item,
  linkClassNames = '',
  subMenuClassNames,
  isCondensed,
  showChildrenSearch
}: MenuItemWithChildrenProps) => {
  const location = useLocation();
  const [childActive, setIsChildActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [keyboardNavIndex, setKeyboardNavIndex] = useState(-1);
  const navigate = useNavigate();

  const scrollIntoViewRef = useRef<HTMLLIElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const scrollToElement = () => {
    scrollIntoViewRef.current?.scrollIntoView({
      behavior: 'instant',
      block: 'nearest',
      start: 'end'
    } as ScrollIntoViewOptions);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    inputRef.current?.focus();
  };

  const handleIsCondensed = () => {
    setSearchTerm('');
    const newValue = !childActive;
    localStorage.setItem(`__menu-${item.id}-is-condensed`, newValue.toString());
    setIsChildActive(newValue);
  };

  // TODO: remove this when we support the search in the condensed view
  useEffect(() => {
    setSearchTerm('');
  }, [isCondensed]);

  useEffect(() => {
    if (location.pathname.startsWith(item.path)) {
      setIsChildActive(location.pathname.startsWith(item.path));
    } else {
      const storedValue = localStorage.getItem(`__menu-${item.id}-is-condensed`);
      if (storedValue != null) {
        const found = storedValue === 'true';

        setIsChildActive(found);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, item.path]);

  useEffect(() => {
    if (keyboardNavIndex >= 0) {
      scrollToElement();
    }
  }, [keyboardNavIndex]);

  useEffect(() => {
    if (childActive) {
      inputRef.current?.focus();
    }
  }, [childActive]);

  const filteredChildren = useMemo(() => {
    if (searchTerm === '') {
      return item.children || [];
    }
    return (
      item.children?.filter((child) => child.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      item.children ||
      []
    );
  }, [item.children, searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      setKeyboardNavIndex(-1);
    }
  }, [searchTerm, filteredChildren]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setKeyboardNavIndex((prevCount) => (prevCount === filteredChildren.length - 1 ? prevCount : prevCount + 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setKeyboardNavIndex((prevCount) => (prevCount === 0 ? 0 : prevCount - 1));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      navigate(filteredChildren[keyboardNavIndex].path);
    }
  };
  return (
    <li
      onMouseOut={() => setKeyboardNavIndex(-1)}
      onMouseOver={() => inputRef.current?.focus()}
      className={`side-nav-item ${isCondensed ? 'side-nav-item--condensed' : ''} ${
        location.pathname === item.path || location.pathname.includes(item.path)
          ? `mm-active ${isCondensed ? 'mm-active--condensed' : ''}`
          : ''
      }`}
      data-testid={`app-menu-menu-item-${item.name}`}>
      <Link
        to="#"
        className={`has-arrow ${isCondensed ? 'has-arrow--condensed' : ''} side-sub-nav-link ${linkClassNames}`}
        onClick={handleIsCondensed}
        aria-expanded={childActive}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box sx={{ width: 18, display: 'flex' }} data-testid="app-menu-item-icon">
            {item.icon}
          </Box>
          <span>{item.name}</span>
        </Stack>
      </Link>

      {showChildrenSearch && !isCondensed && childActive && (
        <Stack direction="row" spacing={4} alignItems="center" className="children-search">
          <div className="input-container">
            <div className="search-icon">
              <SearchIcon />
            </div>
            <input
              ref={inputRef}
              type="text"
              value={searchTerm}
              onKeyDown={onKeyDown}
              onChange={handleSearchChange}
              placeholder="Filter projects..."
              className="children-search-input"
              data-testid="children-search-input"
            />
            {searchTerm && (
              <button type="button" onClick={handleClearSearch} className="clear-button" aria-label="Clear search">
                <CloseIcon />
              </button>
            )}
          </div>
        </Stack>
      )}

      <ul
        className={`${subMenuClassNames} subMenu mm-collapse ${childActive ? `${isCondensed ? '' : 'mm-show'}` : ''}`}>
        {item.childrenReady ? (
          filteredChildren.map((child, i) => {
            return (
              <MenuItemWithoutChildren
                key={child.path}
                item={child}
                onHover={() => setKeyboardNavIndex(i)}
                isCondensed={isCondensed}
                isSelected={i === keyboardNavIndex}
                forwardRef={i === keyboardNavIndex ? scrollIntoViewRef : null}
                dataTestid={`app-menu-menu-item-${child.name}`}
              />
            );
          })
        ) : (
          <li data-testid="app-menu-menu-item-loading">
            <Stack justifyContent="center">
              <img src={dotsLoading} alt="loading" className="new-version-loading" height="25" />
            </Stack>
          </li>
        )}
      </ul>
    </li>
  );
};

const MenuItemWithoutChildren = ({
  item,
  linkClassName = '',
  isCondensed,
  dataTestid,
  isSelected,
  onHover,
  forwardRef
}: MenuItemWithoutChildrenProps) => {
  const location = useLocation();

  return (
    <li
      ref={forwardRef && forwardRef}
      data-tooltip-id={dataTestid}
      onMouseOver={() => onHover && onHover()}
      data-testid={dataTestid ?? `app-menu-menu-item-${item.name}`}
      className={`side-nav-item ${isCondensed ? 'side-nav-item--condensed' : ''} ${
        location.pathname === item.path ? `mm-active ${isCondensed ? 'mm-active--condensed' : ''}` : ''
      }`}>
      <Link
        to={item.path}
        className={`side-nav-link-ref side-sub-nav-link ${isSelected ? 'selected' : ''} ${
          isCondensed ? '' : 'text-truncate'
        } ${linkClassName}`}>
        <Stack direction="row" spacing={1} alignItems="center">
          {item.icon && (
            <Box sx={{ width: 18, display: 'flex' }} data-testid="app-menu-item-icon-1">
              {item.icon}
            </Box>
          )}
          <Tooltip
            title={item.name}
            PopperProps={{
              disablePortal: true
            }}>
            <span
              style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>
              {item.name}
            </span>
          </Tooltip>
        </Stack>
      </Link>
    </li>
  );
};

const AppMenu = ({ isCondensed }: AppMenuProps) => {
  const { user } = useAuth();
  const { queryParams } = useRoute('project_id', 'vendor_id', 'sort');

  const { data: projects, isLoading: isLoadingProjects } = useMenuProjectList({ staleTime: 1000 * 60 * 20 });

  const activeProjects = useMemo(() => {
    if (user) {
      if (user.isAdmin) {
        return projects ?? [];
      }

      // manager can only see projects they have access to (based on project_permissions)
      if (user.isManager) {
        return (projects ?? []).filter((p) =>
          user.project_permissions.find((userPermission) => userPermission.project_id == p.id)
        );
      }
    }
    return [];
  }, [user, projects]);

  const menuItems = useMemo<MenuItemProps[]>(() => {
    const homeRoute = Object.values(queryParams) ? `/?${preparePathParams(queryParams)}` : '/';
    const foundRoutes: MenuItemProps[] = [
      {
        id: 'inbox',
        path: homeRoute,
        name: 'Inbox',
        roles: [ADMIN, MANAGER],
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
              <path d="M22 12h-6l-2 3h-4l-2-3H2" />
              <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
            </g>
          </svg>
        ),
        visible: true
      },
      {
        id: 'approvals',
        path: '/approvals',
        name: 'Approvals',
        roles: [ADMIN],
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M20 8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19a.32.32 0 0 0-.09 0a.88.88 0 0 0-.33-.11H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.94Zm-6-3.53L16.59 8H15a1 1 0 0 1-1-1ZM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3Zm-3.71-6.71L11 15.59l-1.29-1.3a1 1 0 0 0-1.42 1.42l2 2a1 1 0 0 0 1.42 0l4-4a1 1 0 0 0-1.42-1.42Z"
            />
          </svg>
        ),
        visible: true
      },
      {
        id: 'accounts_payable',
        path: '/accounts-payable',
        name: 'Accounts Payable',
        roles: [ADMIN, MANAGER],
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M3 4a1 1 0 0 0-1 1v14a1 1 0 0 0 2 0V5a1 1 0 0 0-1-1Zm18.92 7.62a1 1 0 0 0-.21-.33l-4-4a1 1 0 1 0-1.42 1.42l2.3 2.29H7a1 1 0 0 0 0 2h11.59l-2.3 2.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4-4a1 1 0 0 0 .21-.33a1 1 0 0 0 0-.76Z"
            />
          </svg>
        ),
        visible: true
      },
      {
        id: 'projects',
        path: '/projects',
        name: 'Projects',
        roles: [ADMIN, MANAGER],
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M20,9.67V9.5a7.95,7.95,0,0,0-5.59-7.62l-.06,0a8.32,8.32,0,0,0-2.59-.36A8.21,8.21,0,0,0,4,9.67a3,3,0,0,0,0,5.66,8,8,0,0,0,8,7.17h.23a8.13,8.13,0,0,0,7.68-7.16A3,3,0,0,0,20,9.67ZM12.18,20.5a6,6,0,0,1-6.09-5H17.86A6.09,6.09,0,0,1,12.18,20.5Zm6.82-7H5a1,1,0,0,1,0-2H7a1,1,0,0,0,0-2H6A6.4,6.4,0,0,1,9,4.35V7.5a1,1,0,0,0,2,0V3.59a7.34,7.34,0,0,1,.82-.09H12a6.64,6.64,0,0,1,1,.09V7.5a1,1,0,0,0,2,0V4.32a6.65,6.65,0,0,1,1.18.87A6,6,0,0,1,18,9.5H17a1,1,0,0,0,0,2h2a1,1,0,0,1,0,2Z"></path>
          </svg>
        ),
        visible: true
      }
    ];

    const projectChildren: MenuItemProps[] = [];

    if (!isLoadingProjects) {
      activeProjects.forEach((item) => {
        projectChildren.push({
          id: item.id,
          path: `/projects/${item.id}`,
          name: item.name,
          children: item.children?.map((child) => ({
            id: child.id,
            path: `/projects/${child.id}`,
            name: child.name!,
            roles: [ADMIN, MANAGER],
            parent: {
              id: item.id,
              path: `/projects/${item.id}`,
              name: item.name,
              roles: [ADMIN, MANAGER]
            }
          })),
          roles: [ADMIN, MANAGER]
        });
      });
    }

    foundRoutes.forEach((route) => {
      if (route.id === 'projects') {
        route.childrenReady = !isLoadingProjects;
        route.children = projectChildren;
        route.isProject = true;
        route.showChildrenSearch = true;
      }
    });

    return foundRoutes;
  }, [queryParams, isLoadingProjects, activeProjects]);

  const validMenuItems = useMemo(
    () =>
      menuItems.filter((menuItem) => {
        return user?.permissions?.find?.((group) => menuItem.roles.includes(group)) && menuItem.visible;
      }),
    [menuItems, user]
  );

  return (
    <div data-testid="app-menu-wrapper">
      <div id="feature_tabs_side_bar">
        {menuItems.length ? (
          <ul className="side-nav" style={{ marginBottom: '40px' }} id="menu-bar">
            {validMenuItems.map((item, i) => {
              return (
                <Fragment key={item.path}>
                  {item.header && (
                    <li
                      className={`side-nav-title side-nav-item ${isCondensed ? 'side-nav-item--condensed' : ''}`}
                      key={i + '-el'}>
                      {item.header}
                    </li>
                  )}

                  {item.isProject ? (
                    <MenuProject item={item} isCondensed={isCondensed} />
                  ) : item.children ? (
                    <MenuItemWithChildren
                      item={item}
                      subMenuClassNames="side-nav-second-level"
                      linkClassNames={`side-nav-link ${isCondensed ? 'side-nav-link--condensed' : ''}`}
                      isCondensed={isCondensed}
                      showChildrenSearch={item.showChildrenSearch}
                    />
                  ) : (
                    <MenuItemWithoutChildren
                      item={item}
                      isSelected={false}
                      isCondensed={isCondensed}
                      linkClassName={`side-nav-link ${isCondensed ? 'side-nav-link--condensed' : ''}`}
                    />
                  )}
                </Fragment>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default AppMenu;
