import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';

import tagAPI from '@global-apis/tag';

import { useAuth } from '@global-contexts/auth';
import Tag from '@global-interfaces/Tag';

interface TagContextType {
  isLoading: boolean;
  fetchTags: () => Promise<void>;

  tags: Tag[];
}

export const TagContext = createContext<TagContextType>({} as TagContextType);

const TagProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user, isAuthenticated } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    if (isAuthenticated() && user) {
      fetchTags();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchTags = async () => {
    setIsLoading(true);

    const { data } = await tagAPI.getAll();

    setIsLoading(false);
    setTags(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <TagContext.Provider
      value={{
        isLoading,
        fetchTags,

        tags
      }}>
      {children}
    </TagContext.Provider>
  );
};

export const useTags = () => useContext(TagContext);

export default TagProvider;
