import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import axios from 'axios';

export const config = {
  host: import.meta.env.VITE_BACKEND_HOST,
  graphqlHost: import.meta.env.VITE_DATA_NEXUS_HOST,
  agaveHost: import.meta.env.VITE_AGAVE_HOST
};

export interface PaginatedResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  next_url: string | null;
  previous_url: string | null;
  results: T[];
}

const api = axios.create({
  baseURL: config.host,
  headers: { 'Content-Type': 'application/json' }
});

export const agaveAPI = axios.create({
  baseURL: config.agaveHost,
  headers: { 'Content-Type': 'application/json' }
});

const httpLink = createHttpLink({
  uri: config.graphqlHost
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('__token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

export const graphqlClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache()
});

agaveAPI.interceptors.request.use(function (config) {
  const token = localStorage.getItem('__token');

  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('__token');

  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && window.location.pathname !== '/login') {
      localStorage.removeItem('__token');
      localStorage.removeItem('__refresh-token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;

export const unauthenticatedApi = axios.create({
  baseURL: config.host,
  headers: { 'Content-Type': 'application/json' }
});
