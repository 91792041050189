import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { styled, Box, IconButton, Menu, MenuItem, Typography, Divider } from '@mui/material';
import { blue, green, grey } from '@mui/material/colors';
import { Apps, Sync, AttachMoney } from '@mui/icons-material';

import sageIcon from '@global-assets/images/erp/sage.png';
import procoreIcon from '@global-assets/images/erp/procore.png';
import quickBooksIcon from '@global-assets/images/erp/quickBooks.png';
import quickBooksDesktopIcon from '@global-assets/images/erp/quickBooks_desktop.png';
import quickBooksTimeIcon from '@global-assets/images/erp/quickBooks_time.png';
import clockSharkIcon from '@global-assets/images/erp/clockShark.png';
import dropboxIcon from '@global-assets/images/erp/dropbox.svg';
import docuSignIcon from '@global-assets/images/erp/docusign.webp';
import quickBooksDesktopMacIcon from '@global-assets/images/erp/quickBooks_desktop.png';

import { useAuth } from '@global-contexts/auth';
import {
  SAGE_AVAILABLE,
  PROCORE_AVAILABLE,
  QUICKBOOKS_AVAILABLE,
  QUICKBOOKS_DESKTOP_AVAILABLE,
  QUICKBOOKS_DESKTOP_TIME_AVAILABLE,
  QUICKBOOKS_TIME_AVAILABLE,
  CLOCKSHARK_AVAILABLE,
  PROCORE_TIMECARD_AVAILABLE,
  DROPBOX_AVAILABLE,
  DOCUSIGN_AVAILABLE,
  QUICKBOOKS_DESKTOP_MAC_AVAILABLE,
  PAYMENT_TRANSACTIONS
} from '@global-utils/defaultValues';
import { useUserInteractions } from '@global-contexts/userInteractions';

const _MAP_THIRD_APPS = {
  [PROCORE_AVAILABLE]: {
    name: 'Procore',
    icon: procoreIcon,
    redirectTo: '/erp/procore'
  },
  [PROCORE_TIMECARD_AVAILABLE]: {
    name: 'Procore Timecard',
    icon: procoreIcon,
    redirectTo: '/erp/procore-timecard'
  },
  [SAGE_AVAILABLE]: {
    name: 'Sage',
    icon: sageIcon,
    redirectTo: '/erp/sage'
  },
  [QUICKBOOKS_AVAILABLE]: {
    name: 'QuickBooks',
    icon: quickBooksIcon,
    redirectTo: '/erp/quickbooks'
  },
  [QUICKBOOKS_DESKTOP_AVAILABLE]: {
    name: 'QuickBooks Desktop',
    icon: quickBooksDesktopIcon,
    redirectTo: '/erp/quickbooks-desktop'
  },
  [QUICKBOOKS_DESKTOP_MAC_AVAILABLE]: {
    name: 'QuickBooks Mac',
    icon: quickBooksDesktopMacIcon,
    redirectTo: '/erp/quickbooks-desktop-mac'
  },
  [QUICKBOOKS_DESKTOP_TIME_AVAILABLE]: {
    name: 'QuickBooks Payroll',
    icon: quickBooksDesktopIcon,
    redirectTo: '/erp/quickbooks-desktop-payroll'
  },
  [QUICKBOOKS_TIME_AVAILABLE]: {
    name: 'QuickBooks Time',
    icon: quickBooksTimeIcon,
    redirectTo: '/erp/quickbooks-time'
  },
  [CLOCKSHARK_AVAILABLE]: {
    name: 'ClockShark',
    icon: clockSharkIcon,
    redirectTo: '/erp/clockShark'
  },
  [DROPBOX_AVAILABLE]: {
    name: 'Dropbox',
    icon: dropboxIcon,
    redirectTo: '/erp/dropbox'
  },
  [DOCUSIGN_AVAILABLE]: {
    name: 'DocuSign',
    icon: docuSignIcon,
    redirectTo: '/erp/docusign',
    newRedirectTo: '/new-erp/docusign'
  }
};

const _MAP_INTERNAL_APPS = {
  [PAYMENT_TRANSACTIONS]: {
    name: 'Transactions',
    iconMui: <AttachMoney style={{ fontSize: 26 }} sx={{ color: green[700] }} />,
    redirectTo: '/payment-transactions'
  }
};

export const AppMenu = styled(Menu)({
  display: 'grid'
});

const AppsDropdown = () => {
  const { company, user, flags } = useAuth();
  const { setIsIntegrationsModalOpen } = useUserInteractions();
  const [dropdownOpen, setDropDownOpen] = useState();

  const companyApps = useMemo(() => {
    const integrations = (company.integrations_available && Object.keys(company.integrations_available)) || [];
    const preCompanyIntegrations = integrations.filter((erp) => company.integrations_available[erp]);
    const companyIntegrations = preCompanyIntegrations.find((erp) => erp === PROCORE_AVAILABLE)
      ? [...preCompanyIntegrations, PROCORE_TIMECARD_AVAILABLE]
      : preCompanyIntegrations;

    return Object.entries(_MAP_THIRD_APPS)
      .filter(([appName]) => companyIntegrations.includes(appName))
      .map((item) => {
        if (item[0] === DOCUSIGN_AVAILABLE) {
          return [
            item[0],
            {
              ...item[1],
              redirectTo: user?.company_feature_flags?.new_docusign ? item[1].newRedirectTo : item[1].redirectTo
            }
          ];
        }
        return item;
      })
      .map(([, appProp]) => appProp);
  }, [company.integrations_available, user?.company_feature_flags?.new_docusign]);

  const internalApps = useMemo(() => {
    const foundApps = Object.entries(_MAP_INTERNAL_APPS)
      .filter(([, item]) => {
        if (item.name === 'Transactions') {
          return flags.paymentTransactions;
        }

        return true;
      })
      .map(([, appProp]) => appProp);

    if (company.enable_import_options) {
      foundApps.push({
        name: 'Sync ERPs',
        iconMui: <Sync style={{ fontSize: 26 }} sx={{ color: blue[700] }} />,
        redirectTo: '#',
        onClick: () => setIsIntegrationsModalOpen(true)
      });
    }

    return foundApps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.enable_import_options]);

  const toggleDropdown = () => {
    setDropDownOpen(!dropdownOpen);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (onClick) => {
    setAnchorEl(null);

    if (typeof onClick === 'function') {
      onClick();
    }

    return [];
  };

  return (
    <div>
      <IconButton
        id="apps-menu-demo"
        aria-label="Applications"
        aria-controls={open ? 'apps-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="plain"
        color="neutral"
        onClick={handleClick}
        sx={{ borderRadius: 40, height: 40 }}>
        <Apps sx={{ color: grey[600] }} />
      </IconButton>
      <Menu
        id="apps-menu"
        disableScrollLock
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-labelledby="apps-menu-demo"
        sx={{
          ul: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 96px)',
            padding: '14px',
            gap: '8px'
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        {internalApps.map((app, index) => (
          <Link
            to={app.redirectTo}
            style={{ color: 'inherit', textDecoration: 'inherit' }}
            onClick={toggleDropdown}
            key={index}>
            <MenuItem
              orientation="vertical"
              onClick={() => handleClose(app.onClick)}
              sx={{ justifyContent: 'center', padding: 0 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 48, height: 48 }}>
                  {app.iconMui ?? <img src={app.icon} alt={`${app.name} app logo`} height={26} width={26} />}
                </Box>
                <Typography style={{ fontSize: 12, whiteSpace: 'normal', height: 40, width: 80, textAlign: 'center' }}>
                  {app.name}
                </Typography>
              </Box>
            </MenuItem>
          </Link>
        ))}
        <Divider
          sx={{
            gridColumnEnd: 4,
            gridColumnStart: 1,
            height: 0,
            alignItems: 'center',
            marginBottom: '20px',
            marginTop: '10px'
          }}>
          Apps integrations
        </Divider>
        {companyApps.map((app, index) => (
          <Link
            to={app.redirectTo}
            style={{ color: 'inherit', textDecoration: 'inherit' }}
            onClick={toggleDropdown}
            key={index}>
            <MenuItem
              orientation="vertical"
              onClick={() => handleClose(app.onClick)}
              sx={{ justifyContent: 'center', padding: 0 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 48, height: 48 }}>
                  {app.iconMui ?? <img src={app.icon} alt={`${app.name} app logo`} height={26} width={26} />}
                </Box>
                <Typography style={{ fontSize: 12, whiteSpace: 'normal', height: 40, width: 80, textAlign: 'center' }}>
                  {app.name}
                </Typography>
              </Box>
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </div>
  );
};

export default AppsDropdown;
