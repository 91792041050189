import {
  STATUS_NEW,
  PROCORE,
  QUICKBOOKS,
  QUICKBOOKS_DESKTOP,
  SAGE,
  STATUS_NONE,
  STATUS_SYNCED,
  STATUS_PROCESSING,
  STATUS_FAILED,
  STATUS_READY,
  QUICKBOOKS_DESKTOP_MAC,
  PROCORE_TIMECARD
} from '@global-utils/defaultValues';
import { addDays, isWeekend } from 'date-fns';
import { isHoliday } from 'date-fns-holiday-us';

export const formatDate = (dateValue, UTC = true, time = false) => {
  if (dateValue === null || dateValue === undefined) {
    return null;
  }

  if ((typeof dateValue === 'string' || dateValue instanceof String) && !dateValue.length) {
    return;
  }

  let date = dateValue;

  if (!(dateValue instanceof Date)) {
    date = new Date(dateValue);
  }

  return new Intl.DateTimeFormat('en-US', {
    ...(UTC ? { timeZone: 'UTC' } : undefined),
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    ...(time ? { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false } : undefined)
  }).format(date);
};

export const formatTimestamp = (dateValue: number, UTC = true, time = false) => {
  if (dateValue === null || dateValue === undefined) {
    return null;
  }

  const date = new Date(dateValue);

  return new Intl.DateTimeFormat('en-US', {
    ...(UTC ? { timeZone: 'UTC' } : undefined),
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    ...(time ? { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false } : undefined)
  }).format(date);
};

export const formatToSend = (dateValue) => {
  if (dateValue instanceof Date) {
    return `${dateValue.getFullYear()}-${dateValue.getMonth() + 1}-${dateValue.getDate()}`;
  }

  return null;
};

export const formatCurrency = (value) => {
  const formatter = new Intl.NumberFormat('us', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol'
  });

  const formated = formatter.format(value);

  const negativeNumber = formated.startsWith('-');

  const symbol = negativeNumber ? formated.slice(0, 2) : formated.slice(0, 1);
  const amount = negativeNumber ? formated.slice(2) : formated.slice(1);

  return `${symbol} ${amount}`;
};

export const isNumeric = (value) => {
  return !isNaN(parseInt(value));
};

export const unformatDate = (dateString) => {
  if (dateString === null || dateString === undefined || !dateString.length) {
    return null;
  }

  //MM/DD/YY to YYYY-MM-DD
  const array = dateString.split('/');
  return `20${array[2]}-${array[0]}-${array[1]}`;
};

export const addBusinessDays = (date: Date, days: number) => {
  let result = new Date(date);
  let count = 0;

  while (count < days) {
    result = addDays(result, 1);

    if (!isWeekend(result) && !isHoliday(result)) {
      count++;
    }
  }
  return result;
};

export const erpStatusCollection = (invoice, company, alignLeft = false) => {
  const {
    erp_status,
    ei_dynamics_response,
    quickbooks_response,
    quickbooks_status,
    quickbooks_desktop_response,
    quickbooks_desktop_status,
    procore_response,
    procore_status
  } = invoice;

  const {
    sage_available,
    quickbooks_available,
    quickbooks_desktop_available,
    procore_available,
    quickbooks_desktop_mac_available
  } = company.integrations_available || {};

  // prettier-ignore
  const classNameReady = alignLeft ? 'text-capitalize ribbon-two ribbon-two-info' : 'text-capitalize ribbon-three table-td ribbon-three-info';
  // prettier-ignore
  const classNameProcessing = alignLeft ? 'text-capitalize ribbon-two ribbon-two-info' : 'text-capitalize ribbon-three table-td ribbon-three-warning';
  // prettier-ignore
  const classNameDone = alignLeft ? 'text-capitalize ribbon-two ribbon-two-success' : 'text-capitalize ribbon-three table-td ribbon-three-success';
  // prettier-ignore
  const classNameFailed = alignLeft ? 'text-capitalize ribbon-two ribbon-two-danger' : 'text-capitalize ribbon-three table-td ribbon-three-danger';

  const getErpStatus = (failedResponse, erp, sep) => {
    return {
      none: { text: null, className: null },
      new: { text: null, className: null },
      ready: { text: `Ready to Sync with ${erp} &#x1F7E1;`, className: classNameReady },
      processing: { text: `Syncing with ${erp} &#x1F7E1;`, className: classNameProcessing },
      done: { text: `Synced with ${erp} &#128994;`, className: classNameDone },
      // prettier-ignore
      failed: { text: `Failed to Sync with ${erp}: &#128308; </br> ${sep} ${failedResponse}`, className: classNameFailed }
    };
  };

  //QUICKBOOKS_DESKTOP_MAC has the same status as QBDesktop
  const erps = [
    { erp: SAGE, available: sage_available, status: erp_status },
    { erp: QUICKBOOKS, available: quickbooks_available, status: quickbooks_status },
    { erp: QUICKBOOKS_DESKTOP, available: quickbooks_desktop_available, status: quickbooks_desktop_status },
    { erp: QUICKBOOKS_DESKTOP_MAC, available: quickbooks_desktop_mac_available, status: quickbooks_desktop_status },
    { erp: PROCORE, available: procore_available, status: procore_status },
    { erp: PROCORE_TIMECARD, available: procore_available, status: procore_status }
  ];

  const checkRaibonEnabled = () => {
    return erps.some((erp) => erp.available && erp.status !== STATUS_NEW);
  };

  const enabled = checkRaibonEnabled();

  // prettier-ignore
  const { text: text_erp, className: className_erp } = getErpStatus(ei_dynamics_response, SAGE, '')[sage_available ? erp_status : STATUS_NONE];

  // prettier-ignore
  const { text: text_qb, className: className_qb } = getErpStatus(quickbooks_response, QUICKBOOKS, '-')[quickbooks_available ? quickbooks_status : STATUS_NONE];

  // prettier-ignore
  const { text: text_qbd, className: className_qbd } = getErpStatus(quickbooks_desktop_response, QUICKBOOKS_DESKTOP,'-')[quickbooks_desktop_available ? quickbooks_desktop_status : STATUS_NONE];

  // prettier-ignore
  const { text: text_procore, className: className_procore } = getErpStatus(procore_response, PROCORE, '-')[procore_available ? procore_status : STATUS_NONE];

  // prettier-ignore
  const { text: text_qb_mac, className: className_qb_mac } = getErpStatus(quickbooks_desktop_response, QUICKBOOKS_DESKTOP_MAC, '-')[quickbooks_desktop_mac_available ? quickbooks_desktop_status : STATUS_NONE];

  const texts = [text_erp, text_qb, text_qbd, text_qb_mac, text_procore];
  const classNames = [className_erp, className_qb, className_qbd, className_qb_mac, className_procore];

  const buildText = () => {
    const availableTexts = texts.filter((t) => Boolean(t));

    return availableTexts.join('</br></br>');
  };

  //check if order is relevant here
  const className = classNames.find((cn) => cn) || '';

  const availableErps = erps
    .filter((erp) => erp.available)
    .filter((erp) => ![STATUS_NEW, STATUS_NONE].includes(erp.status));
  const availableErp = availableErps?.[0] || STATUS_NONE;

  const _MAP_RIBBON_STATUS = {
    done: STATUS_SYNCED,
    processing: STATUS_PROCESSING,
    failed: STATUS_FAILED,
    ready: STATUS_READY,
    new: '',
    none: ''
  };

  const label = _MAP_RIBBON_STATUS[availableErp.status];

  return {
    enabled,
    ribbon: {
      className,
      label
    },
    toolTip: buildText()
  };
};

export const checkArrayEquality = (a, b) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((value, index) => value === b[index]);
};

export const browserTls13 = () => {
  if (navigator.userAgent.indexOf('Firefox') != -1) {
    if (parseInt(navigator.userAgent.match(/Firefox\/(.*)/)![1]) >= 63) {
      return true;
    } else {
      return false;
    }
  } else if (navigator.userAgent.indexOf('Edg') != -1) {
    if (parseInt(navigator.userAgent.match(/Edg\/(.*)/)![1]) >= 79) {
      return true;
    } else {
      return false;
    }
  } else if (navigator.userAgent.indexOf('Chrome') != -1) {
    if (parseInt(navigator.userAgent.match(/Chrom(e|ium)\/(.*)/)![2]) >= 69) {
      return true;
    } else {
      return false;
    }
  } else if (navigator.userAgent.indexOf('Edge') != -1) {
    if (parseInt(navigator.userAgent.match(/Edge\/(.*)/)![1]) >= 79) {
      return true;
    } else {
      return false;
    }
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    return true;
  } else {
    return false;
  }
};

export const getBrowser = () => {
  if (navigator.userAgent.indexOf('Firefox') != -1) {
    return 'Firefox';
  } else if (navigator.userAgent.indexOf('Edg') != -1) {
    return 'Edge';
  } else if (navigator.userAgent.indexOf('Chrome') != -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Edge') != -1) {
    return 'Edge';
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    return 'Safari';
  } else {
    return 'Unknown';
  }
};

export const checkNewMentionsIds = (notes, mentioned) => {
  const newMentions = mentioned.filter((mention) => notes.includes(mention.display));
  return newMentions.map((user) => Number(user.id));
};

export const formatPhone = (phone) => {
  if (!phone) {
    return null;
  }
  return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, phone.length)}`;
};

export const formatEin = (ein) => {
  if (!ein) {
    return null;
  }
  return ein.substring(0, 2).concat('-', ein.substring(2, ein.length));
};

export const returnHasEPayment = (vendor) => {
  return vendor?.bank
    ? Boolean(
        (vendor.bank?.account_number && vendor.bank?.routing_number) ||
          vendor.bank?.balance_id ||
          vendor.bank?.mercoa_id
      )
    : false;
};

export const getAlertMessage = (
  status: 'valid' | 'invalid' | 'expired' | 'error' | 'password-error' | 'password-invalid'
) => {
  switch (status) {
    case 'valid':
      return 'Verification successful. Closing...';
    case 'invalid':
      return 'The code you entered is invalid. Please try again.';
    case 'expired':
      return 'The code you entered has expired. Please try again.';
    case 'password-error':
      return 'Password is required to disable 2-factor authentication.';
    case 'password-invalid':
      return 'The password you entered is incorrect. Please try again.';
    default:
      return 'An error occurred. Please try again or contact support.';
  }
};

export const fileRejectionErrorMessage = (fileRejections, accept) => {
  if (fileRejections.length === 0) {
    return null;
  }

  if (fileRejections.length > 0 && fileRejections[0].errors[0].code === 'file-invalid-type') {
    const normalizedAccept = Object.keys(accept).map((key) => {
      const fileTypes = (accept as { [key: string]: string[] })[key];

      if (fileTypes?.length > 0) {
        return fileTypes.map((item) => `*${item}`).join(', ');
      }

      if (key !== 'image/*' && key.includes('image/')) {
        return `*.${key.replace('image/', '')}`;
      }

      switch (key) {
        case 'image/*':
          return 'image';

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return '*.xlsx';

        case 'application/vnd.ms-excel':
          return '*.xls';

        case 'application/msword':
          return '*.doc';

        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return '*.docx';

        case 'application/vnd.ms-powerpoint':
          return '*.ppt';

        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          return '*.pptx';

        case 'application/pdf':
          return '*.pdf';

        case 'text/plain':
          return '*.txt';

        default:
          return '';
      }
    });

    return `Only ${normalizedAccept.join(', ')} files are accepted`;
  }

  if (fileRejections.length > 0 && fileRejections[0].errors[0].code === 'file-too-large') {
    return 'File size is too big';
  }

  if (fileRejections.length > 0 && fileRejections[0].errors[0].code === 'too-many-files') {
    return 'Only one file is accepted';
  }

  return null;
};
