import { ApolloProvider } from '@apollo/client';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { Stack, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from '@sentry/react';

import Routes from '@root/routes/Routes';
import { appTheme } from '@root/styles/theme';

import UnsupportedBrowser from '@global-pages/UnsupportedBrowser';

import AuthProvider from '@global-contexts/auth';
import SwishjamProvider from '@global-contexts/swishjam';
import GTMProvider from '@global-contexts/gtm';
import TagProvider from '@global-contexts/tag';
import LoadingProvider from '@global-contexts/loading';
import ToastProvider from '@global-contexts/toast';
import UserInteractionsProvider from '@global-contexts/userInteractions';
import { LaunchDarklyProvider } from '@global-contexts/launchDarkly';

import { graphqlClient } from '@global-apis/config';

import logo from '@global-assets/images/logo/inbuild_logo_lockup_01_blue.svg';
import dotsLoading from '@global-assets/images/dots-loading.svg';
import '@global-assets/scss/app.scss';

import { browserTls13 } from '@global-utils/Helpers';

// CALL IT ONCE IN THE APP
if (typeof window !== 'undefined') {
  injectStyle();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      retryDelay: 1000
    }
  }
});

const NewVersionAlert = () => {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '100vh' }} gap={6}>
      <img src={logo} alt="logo" width="200px" />

      <Typography variant="h5">New version is available!</Typography>

      <Typography variant="h6">{`You'll be redirected soon`}</Typography>

      <img src={dotsLoading} alt="loading" width="80px" />
    </Stack>
  );
};

const AppFallback = ({ error, resetError }: { error: Error; resetError: () => void }) => {
  if (/ChunkLoadError/.test(error.name)) {
    if (sessionStorage.getItem('chunkErrorPageReloaded') !== 'true') {
      sessionStorage.setItem('chunkErrorPageReloaded', 'true');

      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }

    return <NewVersionAlert />;
  }

  resetError();

  return <></>;
};

const App = () => {
  if (!browserTls13()) {
    return <UnsupportedBrowser />;
  }

  return (
    <ErrorBoundary fallback={AppFallback}>
      <HotkeysProvider
        initiallyActiveScopes={[
          'invoice-management-edit-table',
          'confirmation-modal',
          'invoice-detail-new-line-item-contract-cost-table',
          'invoice-detail-new-line-item-direct-cost-table',
          'invoice-detail-sidebar',
          'pagination-buttons',
          'simple-confirmation-modal',
          'topbar-search',
          'global'
        ]}>
        <ApolloProvider client={graphqlClient}>
          <LoadingProvider>
            <ToastProvider>
              <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <LaunchDarklyProvider>
                    <SwishjamProvider>
                      <TagProvider>
                        <GTMProvider>
                          <ThemeProvider theme={appTheme}>
                            <UserInteractionsProvider>
                              <CssBaseline />
                              <Routes />
                            </UserInteractionsProvider>
                          </ThemeProvider>
                        </GTMProvider>
                      </TagProvider>
                    </SwishjamProvider>
                  </LaunchDarklyProvider>
                </AuthProvider>
                {/* <ReactQueryDevtools client={queryClient} /> */}
              </QueryClientProvider>
            </ToastProvider>
          </LoadingProvider>
        </ApolloProvider>
      </HotkeysProvider>
    </ErrorBoundary>
  );
};

export default App;
